import { reactive } from 'vue'
import { useForm } from '../../Base/useForm'
import { passwordValid } from '@/utils/validator/fields'
import { changePasswordFromPersonalInfo } from '@/http/user/userPersonalInfoApi'
import { FormHandler } from '@/utils/use/formHandler'
import { resetFormData } from '@/utils/utils'

export function useChangePassword () {
  const formData = reactive({
    old_password: '',
    password: ''
  })

  const rules = {
    password: passwordValid,
    old_password: passwordValid
  }

  const formAction = async () => {
    let id = form.route.params.id
    if (!form.route.params.id) id = form.store.getters['auth/getUser'].id

    const { message } = await changePasswordFromPersonalInfo(id, formData)
    await form.store.dispatch('setAlert', {
      type: 'success',
      description: message
    })
    resetFormData(formData)
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors),
    serverErrors: form.serverErrors
  }
}
