<template>
  <div class="profile__info">
    <div class="profile__header">
      <h3 class="profile__fullname">
        {{ user?.name }}
      </h3>

      <form
        class="form form__flex-start"
        @submit.prevent="onSubmit"
      >

        <VInput
          name="old_password"
          id="old_password"
          type="password"
          v-model.trim="formData.old_password"
          :errors="v$.old_password.$errors"
          :server-errors="serverErrors.value?.old_password"
          @blur="validateField"
          @input="validateField"
          :class-input="['input input-long input-placeholder-black input-border-bottom']"
          placeholder="Текущий пароль"
        />

        <VInput
          name="password"
          id="password"
          type="password"
          v-model.trim="formData.password"
          :errors="v$.password.$errors"
          :server-errors="serverErrors.value?.password"
          @blur="validateField"
          @input="validateField"
          :class-input="['input input-long input-placeholder-black input-border-bottom']"
          placeholder="Новый пароль"
        />

        <div class="form__buttons form__buttons-change-password">
          <VButtonLoading
            :is-errors="v$.$error"
            :is-disabled="isSend"
            color="green"
            type="submit"
          >
            Сменить
          </VButtonLoading>
          <button class="btn btn-primary btn-bg-green" @click.prevent="$emit('cancel')">
            Отмена
          </button>
        </div>
      </form>
      <a :href="user?.phone" type="tel" class="profile__tel">
        {{ user?.phone }}
      </a>
    </div>
  </div>
  <span
    v-if="user?.roles?.[0]?.name === 'user' ? 'Аккредитован' : 'Претендент'"
    class="profile__status"
    :class="classStatus"
  >
    {{ user?.status }}
  </span>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import VInput from '@/components/ui/form/VInput'
import { useChangePassword } from '@/use/Form/PersonalArea/useChangePassword'
import VButtonLoading from '@/components/ui/buttons/VButtonLoading'

export default {
  emits: ['cancel'],
  setup () {
    const store = useStore()
    const user = computed(() => {
      return store.getters['auth/getUser']
    })

    const classStatus = computed(() => {
      if (!user.value?.roles) return
      return user.value.roles[0].name === 'user' ? 'profile__status--attention' : 'profile__status--success'
    })

    return {
      user,
      classStatus,
      ...useChangePassword()
    }
  },
  components: {
    VButtonLoading,
    VInput
  }
}
</script>
